/* You can add global styles to this file, and also import other style files */
@charset "utf-8";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@ng-select/ng-select/themes/material.theme.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  /*   -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; */
}

*:focus {
  outline: none;
}

@font-face {
  font-family: "Roboto-Light";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/roboto-v20-latin-300.eot");
  src: local("Roboto Light"), local("Roboto-Light"),
    url("./assets/fonts/roboto-v20-latin-300.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/roboto-v20-latin-300.woff2") format("woff2"),
    url("./assets/fonts/roboto-v20-latin-300.woff") format("woff"),
    url("./assets/fonts/roboto-v20-latin-300.ttf") format("truetype"),
    url("./assets/fonts/roboto-v20-latin-300.svg#Roboto") format("svg");
}

@font-face {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/roboto-v20-latin-regular.eot");
  src: local("Roboto"), local("Roboto-Regular"),
    url("./assets/fonts/roboto-v20-latin-regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/roboto-v20-latin-regular.woff2") format("woff2"),
    url("./assets/fonts/roboto-v20-latin-regular.woff") format("woff"),
    url("./assets/fonts/roboto-v20-latin-regular.ttf") format("truetype"),
    url("./assets/fonts/roboto-v20-latin-regular.svg#Roboto") format("svg");
}

@font-face {
  font-family: "Roboto-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/roboto-v20-latin-500.eot");
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("./assets/fonts/roboto-v20-latin-500.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/roboto-v20-latin-500.woff2") format("woff2"),
    url("./assets/fonts/roboto-v20-latin-500.woff") format("woff"),
    url("./assets/fonts/roboto-v20-latin-500.ttf") format("truetype"),
    url("./assets/fonts/roboto-v20-latin-500.svg#Roboto") format("svg");
}

@font-face {
  font-family: "Roboto-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/roboto-v20-latin-700.eot");
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("./assets/fonts/roboto-v20-latin-700.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/roboto-v20-latin-700.woff2") format("woff2"),
    url("./assets/fonts/roboto-v20-latin-700.woff") format("woff"),
    url("./assets/fonts/roboto-v20-latin-700.ttf") format("truetype"),
    url("./assets/fonts/roboto-v20-latin-700.svg#Roboto") format("svg");
}

@font-face {
  font-family: "Roboto-Black";
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/roboto-v20-latin-900.eot");
  src: local("Roboto Black"), local("Roboto-Black"),
    url("./assets/fonts/roboto-v20-latin-900.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/roboto-v20-latin-900.woff2") format("woff2"),
    url("./assets/fonts/roboto-v20-latin-900.woff") format("woff"),
    url("./assets/fonts/roboto-v20-latin-900.ttf") format("truetype"),
    url("./assets/fonts/roboto-v20-latin-900.svg#Roboto") format("svg");
}

button,
a {
  cursor: pointer;
}

h1,
h2,
h4,
h3,
h5 {
  font-weight: 600 !important;
  font-family: 'Montserrat', sans-serif !important;
}

/* common css for give margin and padding start */
.no-padding {
  padding: 0 !important;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-20 {
  padding-right: 20px;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-right-40 {
  padding-right: 40px;
}

.padding-right-50 {
  padding-right: 50px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-left-30 {
  padding-left: 30px;
}

.padding-left-40 {
  padding-left: 40px;
}

.padding-left-50 {
  padding-left: 50px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-50 {
  padding-top: 50px;
}

.no-margin {
  margin: 0 !important;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-right-50 {
  margin-right: 50px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-left-50 {
  margin-left: 50px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-top-75 {
  margin-top: 75px;
}

.margin-top-80 {
  margin-top: 80px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

/* common css for give margin and padding end */

.head-menu {
  &.mat-menu-panel {
    min-width: 180px;
    border-radius: 10px;
    border: 2px solid #dbdbdb;

    .mat-menu-content {
      button {
        display: flex;
        align-items: center;
        height: 35px;
        color: #555;
        font-size: 14px;
        font-weight: 400;
        font-family: "Montserrat", sans-serif;
      }
    }
  }
}

mat-sidenav-container {
  width: 100%;

  mat-sidenav {
    width: 220px;
    height: 100%;
    border-right: 0 !important;

    app-side-nav-list {

      mat-list-item,
      mat-expansion-panel-header {
        span {
          color: #000000;
          font-size: 13.5px;
          margin-left: 10px;
          align-items: center;
        }
      }
    }

    .mat-expansion-panel {
      background: none;
      box-shadow: none !important;

      .mat-list-item-content {
        padding-right: 0 !important;
      }
    }

    .mat-expansion-panel-header {
      padding: 0 16px 0 8px;
    }

    .mat-expansion-panel-header.mat-expanded {
      height: 48px;
    }

    .mat-expansion-panel-body {
      padding-bottom: 10px;
    }

    .mat-list-base {
      padding-top: 15px;
    }
  }
}

:root {
  --ion-color-white: #ffffff;
  --ion-color-sidebar: #363636;
  --ion-color-black: #555555;
  --ion-color-gray: #808080;
  --ion-color-border: #d3d3d3;
  --ion-color-green: #4ea534;
  --ion-color-input: #f4f4f4;
  --ion-color-input-border: #e9e9e9;
}

.w-100 {
  width: calc(100% - 25px) !important;
}

.w-50 {
  width: calc(50% - 13px) !important;
}

.w-40 {
  width: 40% !important;
}

h2.form-heading {
  margin: 0;
  float: left;
  width: 100%;
}

.searchwrap,
.tableheader {
  width: 100% !important;
  float: left !important;
}

.greenbtn {
  background: var(--ion-color-green);
  color: var(--ion-color-white);
  text-transform: capitalize;
  min-width: 160px;
  height: 42px;
  border-radius: 5px !important;
  border: none;
  padding: 0 10px;
  font-size: 15px;
  font-weight: 600;
}

.redbtn {
  background: #ed1b24;
  color: var(--ion-color-white);
  text-transform: capitalize;
  min-width: 160px;
  height: 42px;
  border-radius: 5px !important;
  border: none;
  font-weight: 600;
  padding: 0 10px;
  font-size: 15px;
}

.bottom-btn.fix-bottom {
  position: fixed;
  bottom: 0;
  right: 0;
}

.closebtn,
.rejectbtn {
  background: none;
  color: #555;
  text-transform: capitalize;
  min-width: 160px;
  height: 42px;
  border-radius: 5px !important;
  padding: 0 10px;
  border: 1px solid #555 !important;
  font-size: 15px;
  font-weight: 600;
}

.rejectbtn {
  border: 1.26px solid #ED1B24;
  color: #ED1B24;
}

.btn-bg-white {
  min-width: 160px;
  height: 42px;
  border-radius: 5px;
  background-color: white;
  color: #4ea534;
  border: 1px solid #4ea534;
  font-size: 14px;
  font-weight: 600;
}

.red-btn {
  min-width: 160px;
  height: 42px;
  border-radius: 5px;
  background-color: white;
  color: #ed1b24;
  border: 1px solid #ed1b24;
  font-family: "Roboto-Bold";
  font-size: 16px;
}

.m-0 {
  margin: 0 !important;
}

.float-right {
  float: right;
}

.formflex {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: calc(100% + 30px);
}

.fieldwrap {
  float: left;
  width: calc(33.3% - 30px);
  margin: 0 30px 0 0;
}

.matfieldwrap {
  width: 100%;
  position: relative;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
  padding-bottom: 0 !important;
}

.mat-form-field-infix {
  padding: 0 !important;
  border-top: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  margin-top: 0 !important;
  padding: 0 !important;
  position: relative;
  height: 42px;
}

input.mat-input-element {
  margin-top: 0 !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline-thick {
  display: none !important;
  opacity: 0 !important;
}

.mat-form-field-outline {
  display: none !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick,
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: var(--ion-color-dark) !important;
}

.mat-input-element {
  caret-color: var(--ion-color-dark);
}

.matfieldwrap {
  width: 100%;
  position: relative;
}

.mat-form-field {
  width: 100%;
  padding: 0 12px;
  border: 1px solid var(--ion-color-black);
  background: var(--ion-color-white);
  margin-bottom: 20px;
  border-radius: 3px;
  line-height: 35px;
}

.mat-form-field input[type="text"],
.mat-form-field input[type="password"],
.mat-form-field input[type="email"],
.mat-form-field input[type="number"],
.mat-form-field .mat-select,
.mat-datepicker-input,
input.mat-chip-input,
input {
  width: 100%;
  font-size: 14px;
  color: var(--ion-color-black);
  height: 42px;
  line-height: 42px;
}

.mat-form-field input::placeholder,
.mat-select-placeholder,
.mat-form-field textarea::placeholder {
  color: var(--ion-color-black);
}

.mat-form-field-suffix .mat-icon-button,
.mat-form-field-prefix .mat-icon-button {
  width: 1.5em !important;
  height: 1.5em;
}

.mat-form-field-prefix .mat-icon {
  color: var(--ion-color-black);
  margin-right: 5px;
}

.mat-form-field-suffix .mat-icon-button .mat-icon {
  color: var(--ion-color-border);
}

.mat-focused {
  border: 1px solid var(--ion-color-black);
}

.mat-button-ripple,
.mat-slide-toggle-ripple,
.mat-checkbox-ripple,
.mat-radio-ripple {
  display: none !important;
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
// -webkit-box-shadow: 0 0 0 30px white inset ! important;

.submitbtn {
  background: var(--ion-color-main);
  color: var(--ion-color-white);
  font-family: "Roboto-Medium";
  font-size: 14px;
  border-radius: 10px;
  width: 100%;
}

.mat-button-disabled {
  background: var(--ion-color-gray);
  color: var(--ion-color-white) !important;
}

.flex {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.dflex {
  display: flex;
  align-items: center;
}

// slect css

.mat-option-text {
  font-family: "Roboto-Regular";
  text-transform: capitalize !important;
  font-size: 14px;
  color: var(--ion-color-black);
}

.mat-select-panel {
  position: relative !important;
  top: 45px !important;
  left: 2px;
  z-index: 99999;
  min-width: calc(100% + 25px) !important;
  border-radius: 0 0 2px 2px !important;
}

.mat-select-panel .mat-option {
  height: 45px !important;
  line-height: 1.2 !important;
  background: var(--ion-color-white);
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(0%) !important;
}

.mat-select-arrow {
  margin: 10px 4px !important;
  color: var(--ion-color-black);
}

/************ My CSS *****************/
.bg-h {
  background: url("/assets/images/background.png");
  background-position: bottom;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-repeat: no-repeat;
  height: 400px;
  background-size: cover;
}

.background {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 50px;
  position: relative;
  z-index: 2;
}

.button-1 {
  background: #01adeb;
  border: none;
  padding: 15px;
  text-transform: uppercase;
  color: white;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
  width: 100%;
}

.button-1:hover {
  background: #47ceff;
}

.party-name {
  h2 {
    color: #01adeb;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.card {
  background: #ffffff;
  padding: 20px;
  border: 1px solid #a9a9a9;
  border-radius: 10px;
  margin: 0 auto;
  width: 100%;

  h1 {
    font-size: 25px;
    font-family: "Roboto-Bold";
  }

  p {
    font-size: 20px;
    padding-bottom: 10px;
    font-family: "Roboto-Regular";
    line-height: 1.5;
  }
}

.logo-1 {
  text-align: center;

  img {
    width: 150px;
  }
}

.otp {
  form {
    input {
      margin: 50px 15px 0 14px;
      text-align: center;
      height: 60px;
      font-size: 16px;
      border: solid 1px #555555;
      border-radius: 5px;
      width: 11.5%;
      font-family: "Roboto-Regular";
    }

    mat-label {
      font-size: 14px;
      float: left;
      width: 100%;
      margin-bottom: 5px;
    }
  }
}

.not-receive {
  font-size: 20px;
  padding: 30px;
  text-align: center;

  span {
    color: #01adeb;
  }
}

.bottom-text {
  font-size: 20px;
  padding-top: 20px;
  text-align: center;

  span {
    color: #01adeb;
  }
}

.register {
  form {
    input {
      margin: 15px 0px 20px;
      padding: 10px;
      font-size: 20px;
      border: solid 1px #555555;
      border-radius: 5px;
      width: 100%;
      font-family: "Roboto-Regular";
    }

    mat-label {
      font-size: 14px;
      float: left;
      padding-bottom: 10px;
      font-family: "Roboto-Light";
      margin-bottom: 5px;
    }
  }
}

mat-label {
  color: #555555;
  font-size: 13px;
  margin-bottom: 5px;
  float: left;
  width: 100%;
  font-weight: 500;
}

.astrisk {
  color: #ed1b24;
}

// checkbox css
.mat-checkbox-frame {
  border-color: #717171;
  border-width: 2px;
  border-radius: 2px;
}

.mat-checkbox-layout .mat-checkbox-label {
  color: #717171;
  font-size: 14px;
  width: 100%;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #717171;
}

.more-actions.mat-menu-panel {
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important;
  border-radius: 4px !important;
}

.auto.mat-menu-panel {
  width: auto !important;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.auto.mat-menu-panel .mat-menu-content:not(:empty) {
  padding-top: 0 !important;
}

.testdrive.mat-menu-panel {
  width: 300px !important;
  // min-width:110px !important;
  // max-width:150px !important;
  border-radius: 0 !important;
}

.sales.more-actions.mat-menu-panel {
  width: 110px !important;
  min-width: 110px !important;
  max-width: 150px !important;
  border-radius: 0 !important;
}

.sales.more-actions button.mat-menu-item {
  justify-content: flex-start;
  padding: 0 10px !important;
}

.one-action.mat-menu-panel {
  min-height: 40px !important;
}

.more-actions .mat-menu-content {
  padding-top: 0 !important;
}

.more-actions button.mat-menu-item {
  width: 100%;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--ion-color-grey1);
  font-family: "Roboto-Regular";
  padding: 0 !important;
  height: 35px;
}

.snackbar {
  background: #01adeb;
  color: var(--ion-color-white);
  text-transform: capitalize;

  * {
    font-family: "Roboto-Regular";
  }
}

.main {
  width: 100%;
  height: auto;
  background-color: white;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  min-height: 70px;
  max-height: 100px;
  align-items: center;
  margin-top: 0 !important;

  h2 {
    font-size: 18px;
    color: #000;
    margin: 0;
  }

  button {
    background: none;
    border: 1px solid #4EA534;
    color: #4EA534;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    cursor: pointer;
    min-width: fit-content;

    &:hover {
      background-color: #4EA534;
      color: #fff;
      transition: 0.3s;
    }

    .mat-icon,
    img,
    svg {
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}

.profile-box {
  float: left;
  width: 100%;

  .image-box {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: relative;
    float: left;
    overflow: hidden;

    &:hover .edit-profile {
      bottom: 0;
    }

    .edit-profile {
      position: absolute;
      width: 100%;
      height: 50px;
      bottom: -50px;
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.3s ease-out;
      text-align: center;

      button {
        color: #fff;
        font-family: "Roboto-Medium";
        font-size: 14px;
        width: 100%;
        height: 100%;
      }
    }
  }

  .sep-box {
    float: left;
    width: calc(100% - 200px);
    margin-left: 25px;

    .fieldwrap {
      float: left;
      width: 100% !important;
    }
  }
}

.fo-half {
  float: left;
  width: 100%;

  .fieldwrap {
    margin-right: 25px !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  background: #f5f5f5;
  float: left;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.filter-menu {
  min-height: auto !important;

  .mat-menu-content {
    padding: 0 !important;

    mat-radio-group {
      mat-radio-button {
        padding: 0 0 10px 0;
      }
    }
  }
}

mat-icon {
  cursor: pointer;
}

.mat-error {
  float: left;
  position: relative;
  color: #f44336;
  font-size: 12px;
}

.pagination {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;

  p {
    margin: 0;
  }

  button {
    color: #00ADEF;
    background: var(--ion-color-white);
    box-shadow: 0px 2px 4px 0px #e5e5e5;
    border-radius: 8px;
    margin: 0 0 0 8px !important;
    min-width: auto;
    padding: 0 12px;

    .material-icons {
      line-height: 0.95;
      margin-right: 0;
    }

    &.active {
      background: #00ADEF;
      color: #fff;
    }
  }

  .pagination-input-group {
    margin-left: 10px;

    .pagination-btn {
      background: none;
      border: 2px solid #00ADEF;
      color: #00ADEF;
      border-radius: 5px;
      font-size: 14px;
      padding: 10px;
      width: 120px;
      margin-left: 5px;
      cursor: pointer;

      &:hover {
        background-color: #00ADEF;
        color: #fff;
        transition: 0.3s;
      }
    }

    .mat-input-element {
      box-shadow: 0px 2px 4px 0px #e5e5e5;
      background: #fff;
      border: 1px solid #0f0f0f;
      text-align: center;
      padding: 0;
    }
  }
}

.pagination {
  display: flex;

  p {
    font-size: 12px;
  }

  div {
    display: flex;
  }
}

/************ Ravi CSS Start ***************/

.date-input {
  .mat-date-range-input-start-wrapper {
    position: inherit;
  }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00ADEF;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #00ADEF !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #00ADEF !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #00ADEF !important;
}

/*********** Ravi CSS End **************/

@media only screen and (max-width: 520px) and (min-width: 320px) {

  app-election-list,
  app-nomination-list {
    mat-accordion {
      mat-expansion-panel {
        mat-expansion-panel-header {
          .mat-content {
            flex-wrap: wrap;

            .ele-list-t {
              flex: 1 1 160px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  .block-d {
    margin-bottom: 20px;

    &.adj-2 {

      flex-flow: column;
      align-items: center;

    }
  }
}

@media only screen and (max-width: 767px) and (min-width: 521px) {

  app-election-list,
  app-nomination-list {
    mat-accordion {
      mat-expansion-panel {
        mat-expansion-panel-header {
          .mat-content {
            flex-wrap: wrap;

            .ele-list-t {
              flex: 1 1 160px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  .block-d {
    margin-bottom: 20px;

    &.adj-2 {

      flex-flow: column;
      align-items: center;

    }
  }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .fields {
    display: inherit;

    .fieldwrap {
      width: 50%;
      margin: 0;

      &:first-child {
        margin-right: 20px;
        width: calc(50% - 20px);
      }

      &:nth-child(3) {
        margin-right: 20px;
        width: calc(50% - 20px);
      }
    }

    .clear {
      width: 50%;
      margin: 25px 0 0;
    }
  }

  .election-box {
    .mat-card {
      display: inherit;
      padding: 20px;

      .leftbox {
        display: flex;
        flex-wrap: wrap;

        .img-box {
          img {
            margin: 0 0 10px 0;
            width: 100px;
            height: 100px;
          }
        }

        div {
          float: left;
          width: 200px;
          margin-bottom: 20px;

          h4 {
            margin: 0;
          }
        }
      }

      button {
        margin: 0;
        float: right;
      }
    }
  }

  .div7,
  .div3 {
    width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding: 0 !important;

  }

  .grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .block-d {
    margin-bottom: 20px;

    &.adj-2 {

      flex-flow: column;
      align-items: center;

    }
  }

  app-dashboard {
    .flex-row {
      flex-flow: column;
    }
  }
}

@media only screen and (max-width: 1200px) and (min-width: 1025px) {
  .fields {
    .clear {
      margin: 0;
    }
  }

  .grid {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .election-box {
    .mat-card {
      display: inherit;
      padding: 20px;

      .leftbox {
        display: flex;
        flex-wrap: wrap;

        img {
          margin: 0 0 10px 0;
          width: 100px;
          height: 100px;
        }

        div {
          float: left;
          width: 200px;
          margin-bottom: 20px;

          h4 {
            margin: 0;
          }
        }
      }

      button {
        margin: 0;
        float: right;
      }
    }
  }


  .block-d {
    min-width: 340px !important;
  }

}

@media only screen and (max-width: 1366px) and (min-width: 1201px) {
  .election-box {
    .mat-card {
      .leftbox {
        div {
          padding: 0 10px;
        }
      }
    }
  }

  .grid {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  .block-d {
    min-width: 340px !important;
  }
}

@media only screen and (min-width: 1367px) {}

.ng-select {
  width: 100%;
  padding: 0 12px;
  border: 1px solid var(--ion-color-black);
  background: var(--ion-color-white);
  border-radius: 3px;
  height: 45px;
  margin-bottom: 20px;
  float: left;

  .ng-select-container {
    min-height: 45px;

    &:after {
      border: none;
    }

    .ng-clear-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ng-arrow-wrapper {
      bottom: 14px !important;
    }

    .ng-value-container {
      padding: 0 !important;

      .ng-input {
        bottom: 12px !important;
      }

      .ng-value {
        display: flex;
        align-items: center;
      }
    }
  }

  .ng-dropdown-panel {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background: #fff;
    left: 0;

    border-radius: 2px;
  }

  .ng-option {
    height: 45px;
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    padding: 0 15px;
  }

  .ng-option:hover:not(.ng-option-disabled),
  .ng-option:focus:not(.ng-option-disabled) {
    background: rgba(0, 0, 0, 0.04);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #EEF1F4 inset ! important;
  box-shadow: 0 0 0 30px #EEF1F4 inset ! important;
}

.mat-drawer-container {
  background: #EEF1F4;
}

.breadcrumbNav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.breadcrumbNav .breadcrumb {
  padding-left: 0;
  margin: 0;

  li {
    list-style-type: none;
    width: auto;
    color: #000000;
  }

  li a {
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 600;
  }

  p {
    margin: 8px 0 0;
  }
}

.page {
  padding: 30px;
}

.box {
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.filterDiv {
  margin-bottom: 10px;

  .mat-button-toggle-group {
    border: 0;
  }

  .mat-button-toggle {
    color: #363636;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px #e5e5e5;
    border-radius: 10px;
    margin: 0 0 15px 8px;
    font-size: 14px;
    text-transform: capitalize;
    border-radius: 8px;
    border: 0 !important;

    .mat-button-toggle-label-content {
      line-height: 38px;
    }
  }

  .mat-button-toggle-checked {
    background-color: #00ADEF;
    color: #ffffff;
  }

  .mat-button-toggle-focus-overlay {
    display: none;
  }
}

.mat-menu-content {
  padding: 0 !important;
}

.mat-menu-panel {
  min-height: auto !important;
}

button.mat-menu-item {
  display: flex;
  align-items: center;
  height: 38px;
  font-size: 14px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;

  .mat-icon {
    margin-right: 8px;
    color: #00ADEF;
  }
}

// table css
.tablewrap {
  overflow-x: auto;
  width: 100%;
  margin-bottom: 15px;
}

.mat-table {
  width: 100%;
  border-radius: 2px;
  background: inherit;
  border-collapse: collapse;
}

tr.mat-header-row,
tr.mat-row {
  height: 41px !important;
  border-bottom: 1px solid #ebeaf5;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type {
  padding-left: 10px !important;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type {
  padding-right: 10px !important;
}

.mat-header-cell,
.mat-cell {
  font-size: 14px;
  text-transform: capitalize;
  white-space: nowrap;
  color: #000000;
  white-space: normal;
  padding-right: 10px !important;
  min-width: 100px !important;
  padding: 0 10px !important;
}

td.mat-cell {
  border-bottom-width: 0 !important;
  cursor: pointer;
}

.mat-header-cell {
  background: #00ADEF;
  color: #ffffff;
  font-weight: 600;
  border-bottom-color: #EC2227 !important;
  border-right: 0;
  border-bottom-width: 3px !important;
}

.mat-cell {
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

//tabblock//
.tabBlock {
  .mat-tab-label {
    opacity: 1;
    padding: 0 8px;
    min-width: auto;
    margin-right: 30px;
  }

  .mat-tab-label-content {
    font-size: 15px;
    color: #000000;
    opacity: 1;
  }

  .mat-tab-header {
    border-bottom: 0;
  }

  .mat-tab-label-active .mat-tab-label-content {
    color: #00ADEF;
    opacity: 1;
  }

  .mat-ink-bar {

    background: none !important;
  }

  .mat-tab-body-content {
    overflow-x: hidden;
  }
}

@media only screen and (min-width: 768px) {

  .mobile,
  .navBtn {
    display: none !important;
  }

  .userBtn {
    margin: 0 !important;
  }
}

@media only screen and (max-width: 767px) {

  .div7,
  .div3 {
    max-width: 100% !important;
    flex: 100% !important;
    padding-right: 0 !important;
  }

  .grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .tableHeader,
  .d-flex,
  .detailDiv,
  .gallery,
  .breadcrumbNav {
    display: block !important;
    width: 100% !important;
  }

  .mat-horizontal-stepper-header-container,
  .max,
  .min {
    width: 100% !important;
    padding: 0 !important;
    margin-bottom: 10px;
    height: 100%;
  }

  .filterDiv .mat-button-toggle-group {
    width: 100%;
    flex-wrap: wrap;

    .mat-button-toggle-label-content {
      line-height: 1.4;
      padding: 0 5px;
    }

    .mat-button-toggle {
      width: 47%;
      white-space: normal;
      align-items: center;
      min-height: 35px;
      display: inline-flex;
      font-size: 13px;
      justify-content: center;
      margin: 0 0 10px;

      &:nth-child(even) {
        margin-left: auto;
      }
    }
  }

  .leftDiv,
  .rightDiv,
  mat-accordion,
  .priority {
    width: 100% !important;
  }

  .footer {
    display: block !important;
    padding: 15px;

    p {
      margin-bottom: 8px !important;
    }

    div {
      text-align: center;
    }

    button {
      margin: 5px !important;
      width: 45% !important;
      white-space: normal;
      min-height: 45px;
      height: auto !important;
      line-height: 1.2 !important;
      vertical-align: top;
    }
  }

  .report {
    margin-bottom: 15px;
  }

  .desktop {
    display: none !important;
  }

  .pagination {
    width: 100%;
    margin-top: 10px;

    .submitbtn {
      width: 30px;
      height: 30px;
      margin-left: 5px !important;
      padding: 0 4px;
    }

    p {
      font-size: 12px;
    }

    div {
      display: flex;
    }
  }

  .submitbtn {
    font-size: 12.5px;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
  }

  .tableHeader .inlineDiv {
    width: 100%;

    .matfieldwrap {
      width: 75%;
    }

    .submitbtn {
      width: auto;
      padding: 0 5px;
      min-width: 30px;

      .mat-button-wrapper span {
        display: none;
      }
    }
  }
}

// stepper css
.reportstepper {
  background: none;

  .mat-horizontal-content-container {
    padding: 0 !important;
  }

  .mat-horizontal-stepper-header-container {
    background: none;
    width: 65%;
    margin: auto;
  }

  .mat-step-header .mat-step-icon-selected,
  .mat-step-icon-state-edit,
  .mat-step-icon-state-done {
    background: #00ADEF !important;
    color: var(--ion-color-white) !important;
    border: 2px solid #00ADEF !important;
  }

  .mat-step-label-selected {
    color: #00ADEF !important;
    font-weight: 500;
  }

  .mat-step-header .mat-step-icon {
    background: #EEF1F4;
    color: #00ADEF;
    border: 2px solid #00ADEF;
  }

  .mat-step-header .mat-step-label.mat-step-label-active {
    color: #00ADEF;
    font-size: 13px;
  }
}

.memberTab {
  .mat-tab-label {
    border: 1px solid #B7B7B7;
    border-radius: 10px 10px 0px 0px;
    margin: 0 5px;
    color: #000000;
    border-bottom: 0;
    opacity: 1 !important;

    img {
      width: 20%;
      margin-right: 10px;
    }

  }

  .mat-ink-bar {
    display: none;
  }

  .mat-tab-label-active {
    background-color: #ffffff;
    font-weight: 600;
  }

}